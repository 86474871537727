<template>
  <div>
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
      <h3 class="mb-0" style="font-size: 20px;">Cloud store</h3>
    </a-row>
    <a-card>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <span class="h5 mb-0">Files</span>
        <a-button type="primary" size="large" style="font-size: 16px; font-weight: 300; border-radius: 2px;" @click="showUploadModal">
          <a-icon type="plus" /> Add New
        </a-button>
        </div>
      <a-table
        ref="table"
        class="data-table"
        :columns="columns"
        :row-key="record => record.id"
        :data-source="uploadData"
        :loading="loading"
        @change="handleTableChange"
        bordered
      >
        <template
          v-for="col in ['created_at', 'file_name', 'name', 'type', 'link']"
          :slot="col"
          slot-scope="text, record"
        >
          <div :key="col" :class="col">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="e => handleChange(e.target.value, record.id, col)"
            />
            <template v-else-if="col === 'link'">
              <a-tooltip title="Copy to clipboard" placement="bottom" >
                <a-icon type="copy" class="text-primary" @click="clipboardHandle(text)" style="cursor: pointer; font-size: 16px; padding: 2px 8px 0 0;" />
              </a-tooltip>
              <a :href="text" target="_blank" class="kit__utils__link" style="text-decoration: underline">{{ text }}</a>
            </template>
            <template v-else-if="col === 'type'">
              <a-icon v-if="record.mime_type.indexOf('image') >= 0" type="picture" style="font-size: 21px;"/>
              <a-icon v-else type="paper-clip" style="text-align: center; font-size: 21px;"/>
            </template>
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <span slot="created_at" slot-scope="created_at">
          {{ $moment(created_at).format('DD.MM.YY HH:mm') }}
        </span>
        <template slot="operation" slot-scope="text, record">
          <div class="editable-row-operations" v-if="checkPermissions(user.permissions, 'settings', 'edit')">
            <span>
              <a href="javascript:;" class="font-size-18 text-primary" @click="() => editRecordModal(record)">
                <a-icon type="edit" />
              </a>
            </span>
            <span>
              <a-popconfirm
                v-if="uploadData.length"
                title="Sure to delete?"
                @confirm="() => onDelete(record.id)"
              >
                <a href="javascript:;" class="font-size-18 text-danger">
                  <a-icon type="delete"/>
                </a>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </a-table>
      <a-modal v-model="newUploadModal"
        title="Add New File"
        class="olimp-admin-modal"
        :destroyOnClose="true"
        footer=''
      >
        <a-form-model
          ref="addUploadForm"
          :model="addUploadForm"
          :rules="addUploadRules"
        >
            <div class="text-center p-2 mb-4">
              <a-upload
                  name="avatar"
                  list-type="picture-card"
                  action=""
                  class="file-uploader"
                  style="display: flex; justify-content: center; margin: 0px;"
                  :show-upload-list="false"
                  @change="fileChange"
              >
                <img v-if="fileUrl" :src="fileUrl" alt="file image" style="height: 146px; border-radius: 4px;" />
                <div v-else>
                  <div v-if="addUploadForm.imgFile !== undefined">
                    <p>
                        <a-icon type="paper-clip" class="ant-upload-text"  style="font-size: 42px" />
                    </p>
                    <p class="ant-upload-text-hint">{{ addUploadForm.imgFile.name }}</p>
                  </div>
                  <div v-else style="font-size: 12px">
                    <a-icon :type="loading ? 'loading' : 'inbox'" class="text-primary"  style="font-size: 48px; margin: 0 !important;" />
                    <div class="ant-upload-text" style="color: #8C8C8C; font-weight: 300; font-size: 14px !important;">
                      Click or drag file to this area to upload<br/>
                      Support for a single or bulk upload.
                    </div>
                  </div>
                </div>
              </a-upload>
            </div>
            <a-form-model-item ref="name" label="Friendly name" prop="friendly_name">
              <a-input v-model="addUploadForm.friendly_name" placeholder="Large input" />
            </a-form-model-item>
            <div class="mt-1">
          <hr>
          <div class="d-flex">
            <a-col class="text-left" :span="12">
              <CustomButton @click="resetUploadForm">
                Cancel
              </CustomButton>
            </a-col>
            <a-col class="text-right" :span="12">
              <a-button type="primary" @click="addUploadSubmit">
                Add
              </a-button>
            </a-col>
          </div>
        </div>
        </a-form-model>
      </a-modal>
      <a-modal
        v-model="editModal"
        title="Edit record"
        :footer="null"
        class="record-modal olimp-admin-modal"
        width="522px"
        :destroyOnClose="true"
      >
        <edit-record-component
          @closeModalFunction="handleEditRecord"
          @submitModalFunction="handleUpdateRecord"
          :record-obj="editRecordObj"
        />
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import banmix from '@/services/bannermix.js'
import accessMix from '@/services/accessmix.js'
import editRecordComponent from '@/views/cloud-store/editRecord.vue'
import CustomButton from '@/components/custom/button/CustomButton'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const columns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    width: '10%%',
    sorter: (a, b) => a.created_at - b.created_at,
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Name',
    dataIndex: 'file_name',
    width: '35%',
    scopedSlots: { customRender: 'file_name' },
  },
  {
    title: 'Friendly Name',
    dataIndex: 'name',
    width: '15%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Type',
    dataIndex: 'mime_type',
    width: '6%',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Link',
    dataIndex: 'full_path',
    width: '25%',
    scopedSlots: { customRender: 'link' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    width: '8%',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'settings',
  components: {
    editRecordComponent,
    CustomButton,
  },
  mixins: [banmix, accessMix],
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      loading: false,
      uploadData: null,
      columns,
      editingKey: '',
      fileUrl: '',
      newUploadModal: false,
      feedbacksData: [],
      pagination: { pageSize: 10 },
      addUploadForm: {
        name: '',
        friendly_name: '',
        imgFile: undefined,
      },
      addUploadRules: {},
      editRecordObj: undefined,
      editModal: false,
      editModalTrigger: true,
    }
  },
  mounted () {
    this.getUploads()
  },
  methods: {
    getUploads() {
      this.loading = true
      const url = '/admin/cloud/1/uploads'
      apiClient.get(url).then((response) => {
        this.uploadData = response.data.data
        this.cacheData = this.uploadData.map(item => ({ ...item }))
        this.loading = false
      }).catch(error => { console.log(error) })
    },
    submitUploadModal() {
      this.$emit('childSubmitFunction')
    },
    showUploadModal() {
      this.newUploadModal = true
    },
    hideUploadModal() {
      this.newUploadModal = false
      this.$emit('closeModalFunction')
    },
    closeUploadModal() {
      this.resetUploadForm()
      this.$emit('closeModalFunction')
    },
    resetUploadForm() {
      this.$refs.addUploadForm.resetFields()
      this.loading = false
      this.editingKey = ''
      this.fileUrl = ''
      this.newUploadModal = false
      this.addUploadForm.imgFile = undefined
      this.addUploadForm.friendly_name = ''
    },
    addUploadSubmit() {
      this.$refs.addUploadForm.validate(valid => {
        if (valid) {
          const url = '/admin/cloud/1/upload'
          const formData = new FormData()
          formData.append('media', this.addUploadForm.imgFile)
          formData.append('friendly_name', this.addUploadForm.friendly_name)
          return apiClient.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }).then((response) => {
            console.log(response, 'RESPONSE')
            this.$notification.success({
              message: 'File "' + response.data.data.name + '" successfully upload',
              description: '',
            })
            const { uploadData } = this
            const newUpload = {
              id: response.data.data.id,
              created_at: response.data.data.created_at,
              file_name: response.data.data.file_name,
              name: response.data.data.name,
              mime_type: response.data.data.mime_type,
              full_path: response.data.data.full_path,
            }
            this.uploadData = [...uploadData, newUpload]
            this.cacheData = this.uploadData.map(item => ({ ...item }))
            this.resetUploadForm()
            this.hideUploadModal()
            this.getUploads()
          }).catch(error => {
            this.$notification.error({
              message: 'Error while downloading new file',
              description: error.message,
            })
          })
        } else {
          return false
        }
      })
    },
    handleTableChange(pagination, filters, sorter) {
      this.fetch({
        sortField: sorter.field,
        sortOrder: sorter.order,
      })
    },
    fetch(params = {}) {
      this.loading = true
      const reqData = { results: 10, ...params }
      const url = '/admin/cloud/1/uploads'
      // const url = '/admin/reviews' // тест пока не готово api
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.loading = false
        this.uploadData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    handleEditRecord(e) {
      this.editModal = false
    },
    handleUpdateRecord(obj) {
      console.log('successfully updated. updating record list...')
      this.getUploads()
      this.editModal = false
    },
    handleChange(value, key, column) {
      const newData = [...this.uploadData]
      const target = newData.filter(item => key === item.id)[0]
      if (target) {
        target[column] = value
        this.uploadData = newData
      }
    },
    onDelete(key) {
      const dataSource = [...this.uploadData]
      const target = dataSource.filter(item => key === item.id)[0]
      const url = '/admin/cloud/uploads/' + key
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'File "' + target.name + '" successfully deleted',
          description: '',
        })
        this.uploadData = dataSource.filter(item => item.id !== key)
        this.cacheData = this.uploadData.map(item => ({ ...item }))
      }).catch(error => {
        this.$notification.error({
          message: 'Error while deleting file',
          description: error.message,
        })
      })
    },
    editRecordModal(obj) {
      this.editRecordObj = obj
      this.editModal = true
    },
    save(key) {
      const newData = [...this.uploadData]
      const newCacheData = [...this.cacheData]
      const target = newData.filter(item => key === item.id)[0]
      const targetCache = newCacheData.filter(item => key === item.id)[0]
      if (target && targetCache) {
        const url = '/admin/cloud/uploads/' + target.id
        return apiClient.post(url, target).then((response) => {
          this.$notification.success({
            message: 'File "' + response.data.data.name + '" successfully updated',
            description: '',
          })
          delete target.editable
          this.uploadData = newData
          Object.assign(targetCache, target)
          this.cacheData = newCacheData
          this.editingKey = ''
        }).catch(error => {
          this.$notification.error({
            message: 'Error while updating file',
            description: error.message,
          })
        })
      }
      this.editingKey = ''
    },
    cancel(key) {
      const newData = [...this.uploadData]
      const target = newData.filter(item => key === item.id)[0]
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.filter(item => key === item.id)[0])
        delete target.editable
        this.uploadData = newData
      }
    },
    fileChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      this.addUploadForm.imgFile = info.file.originFileObj
      this.addUploadForm.name = this.addUploadForm.imgFile.name
      this.loading = false
      if (info.file.originFileObj.type.indexOf('image') === 0) {
        getBase64(info.file.originFileObj, fileUrl => {
          this.fileUrl = fileUrl
          this.addUploadForm.imglink = fileUrl
          this.loading = false
        })
      }
    },
    clipboardMessage(field, v) {
      this.$notification.success({
        message: field + ' successfully copied to clipboard',
        description: v,
      })
    },
    clipboardHandle(v) {
      this.$clipboard(v)
      this.clipboardMessage('', v.slice(0, 36) + '...')
    },
  },
}
</script>
<style>
.ant-table-thead th {
  background-color: #FAFAFA !important;
}
.file-uploader .ant-upload {
  width: 474px !important;
  margin: 0px !important;
  padding: 8px 24px !important;
  box-sizing: border-box !important;
}
.file-uploader .ant-upload img {
  max-width: 400px !important;
}
</style>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.data-table div, .data-table i {
  font-weight: 300;
}
.data-table div {
  color: #595959;
}
.link {
  display: flex;
  width: 100%;
  margin-right: -500px;
}
.link a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.type, .editable-row-operations {
  text-align: center;
}
</style>
